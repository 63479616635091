import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Link } from 'gatsby'
import { validate } from 'email-validator'

import Cookie from './cookie'

export default class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { email: '', status: '', msg: '' }
  }

  handleFormSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()

    const cookie = new Cookie()

    if (!validate(this.state.email)) {
      this.setState({
        status: 'error',
        msg: this.props.translations.invalidAddress,
      })
      return
    }

    this.setState({ status: 'waiting' })

    try {
      const result = await addToMailchimp(this.state.email)

      if (result.result === 'success') {
        this.setState({ status: 'success' })
        cookie.setCookie(
          'SloopTools_Newsletter_Popup',
          'subscriptionSuccess',
          cookie.COOKIE_LIFETIME_IN_MONTHS_SUBSCRIPTION_SUCCESS
        )
      } else {
        this.setState({ status: 'error', msg: result.msg })
      }
    } catch (err) {
      this.setState({ status: 'error', msg: err })
    }
  }

  render() {
    const { translations: t, withLanguagePrefix } = this.props

    const makeTextWithLinks = list => {
      return (
        <span>
          {list.map((el, i) => (
            <span key={i}>
              {el.href ? (
                <Link to={withLanguagePrefix(el.href)}>{el.label}</Link>
              ) : (
                el
              )}
            </span>
          ))}
        </span>
      )
    }

    if (this.state.status === 'success') return <p>{t.success}</p>

    return (
      <form onSubmit={this.handleFormSubmit} method="post" noValidate>
        <div className="form-group">
          <input
            type="email"
            className={`form-control ${
              this.state.status === 'error' ? 'is-invalid' : ''
            }`}
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder={t.placeholder}
            onChange={e => {
              this.setState({ email: e.target.value })
            }}
          />
          {this.state.status === 'error' ? (
            <div
              className="invalid-feedback"
              dangerouslySetInnerHTML={{ __html: this.state.msg }}
            />
          ) : (
            <small id="emailHelp" className="form-text text-muted">
              {makeTextWithLinks(t.notice)}
            </small>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-light slptls-mg-xs-bottom"
          onClick={this.handleFormSubmit}
          disabled={this.state.status === 'sending'}
        >
          {t.buttonLabel}
        </button>
      </form>
    )
  }
}
