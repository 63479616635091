import React from 'react'

import ProductBlock from './ProductBlock'
import ProductHero from './ProductHero'

import connect from '../img/connect.png'

export default ({ translations: t, data, img }) => (
  <ProductBlock
    translations={t}
    img={img}
    className="slptls-connect bg-green-bottom"
    hero={
      <ProductHero
        translations={t.hero}
        image={data.connectHeroImage.childImageSharp}
      />
    }
    productImage={connect}
  />
)
