import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import People from './listOfPersons'

export default ({
  translations: t,
  img,
  data,
  withLanguagePrefix,
  language,
}) => {
  const jobs = data.jobs.edges.filter(
    ({ node }) => !node.fields.language || node.fields.language === language
  )
  // Filter the language and the person out with no picture
  const team = data.team
    ? data.team.edges.filter(
        ({ node }) =>
          node.frontmatter.picture &&
          (!node.fields.language || node.fields.language === language)
      )
    : null
  const board = data.board
    ? data.board.edges.filter(
        ({ node }) =>
          node.frontmatter.picture &&
          (!node.fields.language || node.fields.language === language)
      )
    : null
  return (
    <div>
      <div className="container-fluid p-0">
        <div className="aspect-ratio-box slptls-team-img align-items-end row-eq-height">
          <div className="gatsby-image-outer-wrapper cover-center">
            <Img
              sizes={data.teamImage.childImageSharp.sizes}
              style={{ position: 'absolute' }}
            />
          </div>
          <div className="col-md-12 slptls-overlay">
            <div className="row">
              <div className="col-6 offset-md-6 offset-sm-2 col-md-3 col-sm-5 py-2">
                <h4 className="mb-0">{t.picture.left.header}</h4>
                <p className="mb-0 d-none d-sm-flex">
                  {t.picture.left.subheader}
                </p>
              </div>
              <div className="col-6 col-md-3 offset-sm-0 col-sm-5 py-2">
                <h4 className="mb-0">{t.picture.right.header}</h4>
                <p className="mb-0 d-none d-sm-flex">
                  {t.picture.right.subheader}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container slptls-pd-md">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h2>{t.header}</h2>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="h3">{t.subheader}</p>
          </div>
        </div>
        {team ? (
          <People title={t.team.header} crew={team.map(({ node }) => node)} />
        ) : null}
        {jobs ? (
          <div className="row slptls-pd-md">
            <div className="col-md-6 col-sm-12">
              <h3>{t.search.header}</h3>
            </div>
            <div className="col-md-6 col-sm-12">
              {jobs.map(({ node }, i) => (
                <Link
                  key={i}
                  to={withLanguagePrefix(node.fields.slug)}
                  className="btn btn-light mr-2 mb-2"
                >
                  {node.frontmatter.title}
                </Link>
              ))}
            </div>
          </div>
        ) : null}
        <div className="row slptls-pd-sm">
          <div className="col-12">
            <h2>{t.support.header}</h2>
          </div>
          <div className="col-md-6 col-sm-12">
            {language === 'de' ? (
              <Img sizes={data.supportImageDE.childImageSharp.sizes} />
            ) : (
              <Img sizes={data.supportImageEN.childImageSharp.sizes} />
            )}
          </div>
          <div className="col-md-6 col-sm-12">
            <Link to={withLanguagePrefix('/efre')}>
              {language === 'de' ? (
                <Img sizes={data.efreImageDE.childImageSharp.sizes} />
              ) : (
                <Img sizes={data.efreImageEN.childImageSharp.sizes} />
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
