import React from 'react'
import ArticleCarousel from './ArticleCarousel';

export default ({ translations: t, img, data }) => (
  <div className="slptls-pd-md bg-light">
    <div className="container">
      <div className="row">
        <div className="col">
          <h2>{t.header}</h2>
        </div>
      </div>
      <ArticleCarousel
        items={data.news.edges.map(({ node }) => node)}
        img={img}
        translations={t}
        className="right"
        startCarouselWith={data.news.edges.length - 1}
      />
    </div>
  </div>
)
