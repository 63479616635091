import React from 'react'
import Img from 'gatsby-image'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showVideo: false }
  }

  showVideo = () => {
    this.setState({ showVideo: true })
  }

  hideVideo = () => {
    this.setState({ showVideo: false })
  }

  render() {
    const { translations: t, image, hideVideo } = this.props

    return (
      <div className="position-relative">
        <ReactCSSTransitionGroup
          transitionName="slptls-fade"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {this.state.showVideo ? (
            <div className="slptls-video-container">
              <div className="close-video" onClick={this.hideVideo} />
              <div className="aspect-ratio-16x9">
                <div>
                  <video
                    src={t.videoSrc}
                    playsInline
                    autoPlay
                    onEnded={this.hideVideo}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </ReactCSSTransitionGroup>
        {hideVideo ? null : (
          <div className="slptls-video-play" onClick={this.showVideo} />
        )}
        <Img sizes={image.sizes} />
      </div>
    )
  }
}
