import React from 'react'
import Img from 'gatsby-image'
import makeCarousel from './makeCarousel'
import ReactSwipeEvents from 'react-swipe-events'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Card = ({ i, item, img, setActive, t, className }) => {
  return (
    <div key={i} className={className} onClick={() => setActive(i)}>
      <div className="card">
        <Img
          className="card-img-top"
          sizes={item.frontmatter.picture.sharp.sizes}
          alt={item.frontmatter.title}
        />
        <div className="card-body">
          <h4 className="card-title">{item.frontmatter.title}</h4>
          <div
            className="card-text"
            dangerouslySetInnerHTML={{ __html: item.html }}
          />

          {item.frontmatter.link ? (
            <OutboundLink href={item.frontmatter.link} className="card-link">
              {t.linkLabel}
            </OutboundLink>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const ArticleCarousel = ({
  translations: t,
  img,
  active,
  gotoNext,
  gotoPrev,
  setActive,
  items,
  className,
}) => {
  const getClassName = i => {
    if (i === false) return 'carousel-item height-holder'
    if (i === active - 4) return 'carousel-item left-out'
    if (i === active - 3) return 'carousel-item left-3'
    if (i === active - 2) return 'carousel-item left-2'
    if (i === active - 1) return 'carousel-item left-1'
    if (i === active) return 'carousel-item active'
    if (i === active + 1) return 'carousel-item right-1'
    if (i === active + 2) return 'carousel-item right-2'
    if (i === active + 3) return 'carousel-item right-3'
    if (i === active + 4) return 'carousel-item right-out'
    return 'carousel-item hidden'
  }

  const checkedGotoPrev = () => {
    if (active > 0) {
      gotoPrev()
    }
  }

  const checkedGotoNext = () => {
    if (active < items.length - 1) {
      gotoNext()
    }
  }

  return (
    <div className="position-relative">
      <div className={`row slptls-carousel ${className}`}>
        <Card
          i="placeholder"
          className={getClassName(false)}
          img={img}
          item={items.reduce((acc, item) =>
            acc.excerpt.length > item.excerpt.length ? acc : item
          )}
          t={t}
        />
        <ReactSwipeEvents
          onSwipedLeft={checkedGotoNext}
          onSwipedRight={checkedGotoPrev}
        >
          <div>
            {items.map((item, i) =>
              Card({ i, item, img, setActive, t, className: getClassName(i) })
            )}
          </div>
        </ReactSwipeEvents>
      </div>
      <div className={`slptls-carousel-controls ${className}`}>
        <div className="btn-group" role="group" aria-label="Carousel control">
          <button
            type="button"
            className={`btn btn-light slptls-prev ${
              active === 0 ? 'disabled' : ''
            }`}
            onClick={checkedGotoPrev}
          >
            <span>Prev</span>
          </button>
          <button
            type="button"
            className={`btn btn-light slptls-next ${
              active === items.length - 1 ? 'disabled' : ''
            }`}
            onClick={checkedGotoNext}
          >
            <span>Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default makeCarousel(ArticleCarousel)
