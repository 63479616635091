export default {
  lang: `de`,
  head: {
    title: `SloopTools`,
    meta: {
      description: `SloopTools – the independent SCADA ecosystem`,
      keywords: `SloopTools,SCADA,HMI,WinCCOA`,
    },
  },
  navbar: {
    header: 'SloopTools',
    buttons: [
      {
        label: `Store`,
        href: `https://store.slooptools.com`,
      },
      {
        label: `Registrieren`,
        href: `https://store.slooptools.com/user/register`,
      },
    ],
  },
  hero: {
    header: `SloopTools`,
    subheader: `The independent SCADA ecosystem`,
    videoSrc: '/SloopTools_DE.mp4',
  },
  intro: {
    menuLabel: 'Home',
    image: `logo_large`,
    header: `Der Single Point of Information im SCADA/HMI Markt.`,
    text: `SloopTools verbindet SCADA / HMI OEMs, Integratoren sowie Endkunden 
      auf einer herstellerunabhängigen Plattform.
      Dafür hat SloopTools verschiedenste Services geschaffen.`,
  },
  platform: {
    hero: {
      header: `SloopTools platform`,
      subheader: `The independent SCADA ecosystem`,
      videoSrc: '/SloopTools_Platform_DE.mp4',
    },
    accordion: {
      text: `Wollen Sie neue Mehrwerte Ihres Produktes durch offene Innovation erschaffen? SloopTools platform wurde geschaffen, um ihr digitales Ökosystem zu erschaffen. Lassen Sie Ihr Produkt durch offene Innovation und Ihre Community wachsen.`,
    },
    menuLabel: 'Platform',
    header: `SloopTools platform`,
    subheader: `Wollen Sie neue Mehrwerte Ihres Produktes durch offene Innovation erschaffen? SloopTools platform wurde geschaffen, um ihr digitales Ökosystem zu erschaffen. Lassen Sie Ihr Produkt durch offene Innovation und Ihre Community wachsen.`,
    buttons: [
      {
        label: `Kontaktieren Sie uns`,
        href: `mailto:info@slooptools.com?subject=Kontaktieren%20Sie%20mich%20zu%20SloopTools%20platform&body=Ich%20habe%20Interesse%20an%20SloopTools%20Platform%20und%20bitte%20um%20Kontaktaufnahme.`,
      },
    ],
    features: {
      header: `SloopTools platform Features`,
      items: [
        {
          icon: 'marketplace',
          header: `Digitaler Marktplatz`,
          text: `Ein vollwertiger Store, der sich auf die Nutzung als B2B-Marktplatz konzentriert und 24/7 verfügbar ist.`,
        },
        {
          icon: 'business_system',
          header: `Business System`,
          text: `Die Transaktion zwischen Kunden und Anbietern digitaler Dienste mit Vermittlungsgebühr erfordert ein neues Geschäftssystem`,
        },
        {
          icon: 'community',
          header: `Community Builder`,
          text: `Der Treibstoff Ihres Ökosystems ist die Community. SloopTools hat mehrere Community Builder-Module entwickelt`,
        },
        {
          icon: 'paas_service',
          header: `Platform As A Service`,
          text: `Die SloopTools-Plattform ist modular aufgebaut und kann mit offenen APIs in alle Richtungen erweitert werden.`,
        },
        {
          icon: 'provider_admin',
          header: `Provider Administration`,
          text: `Das umfangreiche Provider-Backend ermöglicht es den Providern, alle notwendigen Einstellungen selbst vorzunehmen und unterstützt die bestmögliche Platzierung des Produkts`,
        },
        {
          icon: 'software',
          header: `Software / Service`,
          text: `Umfangreiche Funktionen zum erfolgreichen Platzieren und Verteilen von Produkten und Dienstleistungen für die Industrie über den Marktplatz`,
        },
      ],
    },
    products: {
      header: `Referenzen`,
      //linkLabel: `Im Store öffnen`,
    },
  },
  store: {
    hero: {
      header: `SloopTools store`,
      subheader: `The independent SCADA ecosystem`,
      videoSrc: '/SloopTools_DE.mp4',
    },
    accordion: {
      text: `Der SloopTools store generiert aus Ihrer SCADA / HMI-Funktionalität ein qualitativ hochwertiges und optimal platziertes Add-on. Im SloopTools store können Sie SCADA / HMI Add-ons kaufen und verkaufen.`,
    },
    menuLabel: 'Store',
    header: `SloopTools store`,
    subheader: `Der SloopTools store generiert aus Ihrer SCADA / HMI-Funktionalität ein qualitativ hochwertiges und optimal platziertes Add-on. Im SloopTools store können Sie SCADA / HMI Add-ons kaufen und verkaufen.`,
    buttons: [
      {
        label: `Store öffnen`,
        href: `https://store.slooptools.com`,
      },
    ],
    features: {
      header: `SloopTools store Features`,
      items: [
        {
          icon: 'presentation',
          header: `Add-on Präsentation`,
          text: `Firmen können Ihre Add-ons vorstellen und mit Zusatzinformation versehen.`,
        },
        {
          icon: 'easy_search',
          header: `Einfache Suche`,
          text: `Firmen können nach fehlenden Funktionalitäten oder Add-ons suchen.`,
        },
        {
          icon: 'quality_gate',
          header: `Quality Gate`,
          text: `Add-ons werden von SloopTools einer eingehende Qualitätskontrolle unterzogen.`,
        },
        {
          icon: 'centralized_billing',
          header: `Zentrale Kostenabrechnung`,
          text: `SloopTools verwaltet die Geldtransaktionen für die Firmen.`,
        },
        {
          icon: 'contract_management',
          header: `Vertragsabwicklung`,
          text: `SloopTools übernimmt die Vertragsabwicklung für die Firmen.`,
        },
        {
          icon: 'different_license_types',
          header: `Verschiedene Lizenztypen`,
          text: `Add-ons können per Einmal-, Abo- oder „Pay Per Use“-Lizenz verkauft werden.`,
        },
        {
          icon: 'ticket_system',
          header: `Ticketsystem`,
          text: `Das zentrale Ticketsystem übernimmt die Kommunikation zwischen den Firmen.`,
        },
        {
          icon: 'demo_licenses',
          header: `Demolizenzen`,
          text: `Eine Demolizenz für ein Add-on kann zur Testung einfach und schnell heruntergeladen werden.`,
        },
        {
          icon: 'additional_services',
          header: `Zusätzliche Services und Features`,
          text: `Zu den Add-ons können zusätzlich Services (Wartung, Consulting, etc.) und Features angeboten werden.`,
        },
      ],
    },
    products: {
      header: `Featured Add-Ons`,
      linkLabel: `Im Store öffnen`,
    },
  },
  connect: {
    hero: {
      header: `SloopTools connect`,
      subheader: `The independent SCADA ecosystem`,
      videoSrc: '/SloopTools_Connect_DE.mp4',
    },
    accordion: {
      text: `SloopTools connect schafft eine Online-Plattform innerhalb der Industriewelt. Mit dieser Plattform können die Teilnehmer zusammenarbeiten und sich gegenseitig unterstützen, um ihr Geschäft zu optimieren.`,
    },
    menuLabel: 'Connect',
    header: 'SloopTools connect',
    subheader: `SloopTools connect schafft eine Online-Plattform innerhalb der Industriewelt. Mit dieser Plattform können die Teilnehmer zusammenarbeiten und sich gegenseitig unterstützen, um ihr Geschäft zu optimieren.`,
    picturelable: 'Play Movie',
    features: {
      header: `SloopTools connect Features`,
      items: [
        {
          icon: 'presentation_company',
          header: `Firmen Präsentation`,
        },
        {
          icon: 'easy_search_c',
          header: `Einfache Suche`,
        },
        {
          icon: 'find_services',
          header: `Services finden`,
        },
        {
          icon: 'central_communication',
          header: `Zentrale Kommunikation`,
        },
        {
          icon: 'network',
          header: `Netzwerken`,
        },
      ],
    },
    buttons: [
      {
        label: `Mehr Informationen ...`,
        href: `http://connect.slooptools.com`,
      },
    ],
  },
  news: {
    menuLabel: `News`,
    header: `Neuigkeiten`,
    linkLabel: `Zum Artikel`,
  },
  partners: {
    menuLabel: `Partner`,
    header: `Partner über SloopTools`,
  },
  team: {
    menuLabel: `Team`,
    header: `Wir sind SloopTools`,
    subheader: `Zwei Profis aus der Branche die durch PartnerInnen und freie MitarbeiterInnen ergänzt werden.`,
    picture: {
      left: {
        header: `Alexander Taker`,
        subheader: `Software Entwicklung`,
      },
      right: {
        header: `Lukas Schopp`,
        subheader: `Geschäftsführer & Gründer`,
      },
    },
    search: {
      header: `Aktuell suchen wir:`,
    },
    support: {
      header: `Unterstützt von:`,
    },
    team: {
      header: `Team`,
    },
    board: {
      header: `Business Advisory Board`,
    },
  },
  footer: {
    company: {
      name: `SloopTools GmbH`,
      address: {
        street: `Winzerweg 15a`,
        city: `Eisenstadt`,
        zip: `7000`,
        country: `Österreich`,
      },
      email: `info@slooptools.com`,
      additional: [`UID-Nummer: ATU73030846`, `Firmenbuch: FN487006k`],
    },
    newsletter: {
      header: `Newsletter`,
      popupHeader: 'Melde dich bei unserem Newsletter an',
      popupSubheader: 'Wir bleiben in Kontakt',
      placeholder: `E-Mail-Adresse eingeben`,
      notice: [
        `Mit der Anmeldung akzeptiere ich die `,
        {
          label: `Datenschutzerklärung`,
          href: `/dataprivacystatement`,
        },
        `.`,
      ],
      buttonLabel: `Absenden`,
      success: `Die Anmeldung zum Newsletter war erfolgreich. Danke!`,
      invalidAddress: 'Die E-Mail-Adresse ist ungültig.',
    },
    navigation: {
      header: `Navigation`,
      additional: [
        {
          label: `Impressum`,
          href: `/imprint`,
        },
        {
          label: `Datenschutz`,
          href: `/dataprivacystatement`,
        },
        {
          label: `Zum Store`,
          href: `https://store.slooptools.com`,
        },
        {
          label: `Registrieren`,
          href: `https://store.slooptools.com/user/register`,
        },
      ],
    },
    copyright: `© SloopTools GmbH. Alle Rechte vorbehalten.`,
  },
}
