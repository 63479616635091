import React from 'react'

import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Features from './Features'

const OptionalLink = ({ href, children }) =>
  href ? <OutboundLink href={href}>{children}</OutboundLink> : { children }

export default ({
  translations: t,
  data,
  img,
  hero,
  productImage,
  className,
  footer,
}) => (
  <div className={`slptls-pd-md ${className}`} style={{ paddingTop: 0 }}>
    {hero && hero}
    <div className="container">
      <div className="row align-items-center py-5">
        <div className="col-md-6 col-sm-12">
          <div className="card">
            <OptionalLink href={t.buttons && t.buttons[0] && t.buttons[0].href}>
              <img src={productImage} className="card-img" />
            </OptionalLink>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <h2 className="slptls-logo slptls-mg-xs-top">
            <span>{t.header}</span>
          </h2>
          <p className="h3">{t.subheader}</p>
          {t.buttons.map(({ label, href }, i) => (
            <OutboundLink key={i} href={href}>
              <div className="w-100 text-center">
                <button className="btn slptls-button">{label}</button>
              </div>{' '}
            </OutboundLink>
          ))}
        </div>
      </div>
      <Features translations={t.features} img={img} />
    </div>
    {footer}
  </div>
)
