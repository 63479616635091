import React from 'react'
import Img from 'gatsby-image'

/* No - Title / Text is handled in the graphql query | No picture is filtered out in team.js */
const Person = ({ person: p}) => {
  return (
    <div className="row mt-3">
      <div className="col-12 col-sm-6 col-md-2">
        <Img
          sizes={p.frontmatter.picture.sharp.sizes}
          alt={p.frontmatter.title}
        />
      </div>
      <div className="col-12 col-sm-6 col-md-10">
        <h4>{p.frontmatter.title}</h4>
        <h5>{p.frontmatter.subtitle}</h5>
        <div className="d-none d-md-block" dangerouslySetInnerHTML={{ __html: p.html }} />
      </div>
      <div className="col-12 d-md-none" dangerouslySetInnerHTML={{ __html: p.html }} />
    </div>
  )
}

export default ({ crew, title }) => {
  return (
    <div className="mt-4">
      <h2>{title}</h2>
      {crew.map( (p, i) => {
        return <Person person={p} key={i} />
      })}
    </div>
  )
}