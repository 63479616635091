import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import NewsletterForm from './NewsletterForm'

export default ({ translations: t, menuItems, withLanguagePrefix }) => {
  const menu = [
    ...menuItems.map(({ label, id }) => ({ label, href: `#${id}` })),
    ...t.navigation.additional,
  ]

  const splitMenu = [
    menu.slice(0, Math.ceil(menu.length / 2)),
    menu.slice(Math.ceil(menu.length / 2)),
  ]

  return (
    <footer className="slptls-pd-md bg-light bg-green-left">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-4 col-md-4 col-sm-12"
            itemScope
            itemType="http://schema.org/Corporation"
          >
            <h2 className="pb-3" itemProp="legalName">
              {t.company.name}
            </h2>
            <p>
              <span
                itemProp="address"
                itemScope
                itemType="http://schema.org/PostalAddress"
              >
                <span itemProp="streetAddress">{t.company.address.street}</span>
                <br />
                <span itemProp="postalCode">{t.company.address.zip}</span>{' '}
                <span itemProp="addressLocality">{t.company.address.city}</span>
                <br />
                <span itemProp="addressCountry">
                  {t.company.address.country}
                </span>
              </span>
              <br />
              <a href={`mailto:${t.company.email}`} itemProp="email">
                {t.company.email}
              </a>
              {t.company.additional.map((line, i) => (
                <span key={i}>
                  <br />
                  {line}
                </span>
              ))}
            </p>
          </div>
          <div id="nl-footer" className="col-lg-4 col-md-4 col-sm-12">
            <h3 className="pb-3">{t.newsletter.header}</h3>
            <NewsletterForm
              translations={t.newsletter}
              withLanguagePrefix={withLanguagePrefix}
            />
          </div>
          <div id="nav-footer" className="col-lg-4 col-md-4 col-sm-12">
            <h3 className="pb-3">{t.navigation.header}</h3>

            <nav className="nav row no-gutters">
              {splitMenu.map((items, i) => (
                <div key={i} className="col">
                  {items.map(({ label, href }, j) =>
                    href.match(/^https?:/) ? (
                      <OutboundLink
                        key={j}
                        href={href}
                        className="btn-link btn-block"
                      >
                        {label}
                      </OutboundLink>
                    ) : (
                      <Link
                        key={j}
                        to={withLanguagePrefix(href)}
                        className="btn-link btn-block"
                      >
                        {label}
                      </Link>
                    )
                  )}
                </div>
              ))}
            </nav>
          </div>
        </div>
        <div className="row slptls-pd-sm-top">
          <div className="col text-center">
            <p className="small">{t.copyright}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
