import React from 'react'
import Cookies from 'universal-cookie'

const COOKIE_LIFETIME_IN_MONTHS_CLOSED = 1
const COOKIE_LIFETIME_IN_MONTHS_SUBSCRIPTION_SUCCESS = 120

export default class extends React.Component {
  cookies = new Cookies()

  getCookie = (purpose = 'SloopTools') => {
    return this.cookies.get(purpose)
  }

  setCookie = (
    purpose = 'SloopTools',
    reason = 'used',
    months = COOKIE_LIFETIME_IN_MONTHS_CLOSED
  ) => {
    const cookies = new Cookies()

    const d = new Date()
    d.setMonth(d.getMonth() + months)

    cookies.set(purpose, reason, {
      path: '/',
      expires: d,
    })
  }
}
