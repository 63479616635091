import React from 'react'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import { Collapse } from 'reactstrap'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuCollapsed: true }
  }

  toggleMenu() {
    this.setState({ menuCollapsed: !this.state.menuCollapsed })
  }

  render() {
    const {
      menuItems,
      languageItems,
      translations: t,
      withLanguagePrefix,
    } = this.props

    return (
      <>
        <Location>
          {({ location }) => (
            <div className="navbar navbar-expand-lg fixed-top bg-light">
              <div className="container">
                <Link to={withLanguagePrefix('#top')} className="navbar-brand">
                  <span>{t.header}</span>
                </Link>
                <button
                  className={`navbar-toggler ${
                    this.state.menuCollapsed ? 'collapsed' : ''
                  }`}
                  type="button"
                  aria-controls="navbarResponsive"
                  aria-expanded={!this.state.menuCollapsed}
                  aria-label="Toggle navigation"
                  onClick={this.toggleMenu.bind(this)}
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <Collapse
                  isOpen={!this.state.menuCollapsed}
                  navbar
                  className="justify-content-end"
                  id="navbarResponsive"
                >
                  <ul className="navbar-nav">
                    {menuItems.map(({ id, label }) => (
                      <li key={id} className="nav-item">
                        <Link
                          className="nav-link"
                          to={withLanguagePrefix(`#${id}`)}
                        >
                          {label}
                        </Link>
                      </li>
                    ))}
                    {languageItems.map(({ short, shortName, title }) => (
                      <li key={short} className="nav-item color-primary">
                        <Link
                          to={`/${short}/${location.pathname.substring(
                            withLanguagePrefix('/').length
                          )}${location.hash}`}
                          className="nav-link font-weight-bold"
                          title={title}
                        >
                          {shortName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  {t.buttons.map(({ label, href }, i) => (
                    <OutboundLink
                      key={i}
                      className="btn btn-primary slptls-mg-xs-right"
                      href={href}
                    >
                      {label}
                    </OutboundLink>
                  ))}
                </Collapse>
              </div>
            </div>
          )}
        </Location>
        <div className="after-fixed-top"></div>
      </>
    )
  }
}

export default Navbar
