module.exports = {
  languages: [
    {
      short: 'de',
      name: 'Deutsch',
      shortName: 'DE',
      title: 'Auf Deutsch umschalten',
    },
    {
      short: 'en',
      name: 'English',
      shortName: 'EN',
      title: 'Switch to English',
    },
  ],
  defaultLanguage: 'en',
}
