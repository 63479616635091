import React from 'react'
import PartnerCarousel from './PartnerCarousel';

import shuffle from 'lodash/shuffle'

export default ({translations: t, data, img}) => (
  <div className="slptls-pd-md bg-yellow-red-bottom">
    <div className="container" id="partner">
      <div className="row slptls-pd-xs-bottom">
        <div className="col">
          <h2>{t.header}</h2>
        </div>
      </div>
      <PartnerCarousel
        items={shuffle(data.partners.edges.map(({ node }) => node))}
        img={img}
        translations={t}
        className="partners"
      />
    </div>
  </div>
)
