import React from 'react'
import Img from 'gatsby-image'
import ReactSwipeEvents from 'react-swipe-events'
import makeCarousel from './makeCarousel'

const Quote = ({ i, item, className }) => {
  return (
    <div key={i} className={className}>
      <blockquote className="blockquote">
        <div className="mb-0" dangerouslySetInnerHTML={{ __html: item.html }} />
        <footer className="blockquote-footer">
          {item.frontmatter.author} <span>– {item.frontmatter.company}</span>
        </footer>
      </blockquote>
    </div>
  )
}

const Logo = ({ i, active, item, img, setActive }) => (
  <div
    key={i}
    className="col-4 col-4 col-md-3 col-sm-4 cursor-pointer"
    onClick={() => setActive(i)}
  >
    <Img
      sizes={
        i === active
          ? item.frontmatter.picture.sharp.sizes
          : item.frontmatter.picture.gray.sizes
      }
      className="img-fluid"
    />
  </div>
)

const PartnerCarousel = ({
  translations: t,
  img,
  active,
  gotoNext,
  gotoPrev,
  setActive,
  items,
  className,
}) => {
  const getClassName = i => {
    if (i === false) return 'carousel-item height-holder'
    if (i < active) return 'carousel-item left-out'
    if (i === active) return 'carousel-item active'
    return 'carousel-item right-out'
  }

  const checkedGotoPrev = () => {
    if (active > 0) {
      gotoPrev()
    }
  }

  const checkedGotoNext = () => {
    if (active < items.length - 1) {
      gotoNext()
    }
  }

  return (
    <div>
      <div className="row align-items-center">
        <div className="col-md-1 d-none d-md-block">
          <a
            className={`btn btn-light slptls-prev ${
              active === 0 ? 'disabled' : ''
            }`}
            onClick={() => {
              if (active > 0) {
                gotoPrev()
              }
            }}
          >
            <span>Prev</span>
          </a>
        </div>
        <div className="col-md-10 col-sm-12 slptls-carousel">
          <Quote
            i="placeholder"
            className={getClassName(false)}
            img={img}
            item={items.reduce(
              (acc, item) => (acc.html.length > item.html.length ? acc : item)
            )}
            t={t}
          />
          <ReactSwipeEvents
            onSwipedLeft={checkedGotoNext}
            onSwipedRight={checkedGotoPrev}
          >
            <div>
              {items.map((item, i) =>
                Quote({ i, item, className: getClassName(i) })
              )}
            </div>
          </ReactSwipeEvents>
        </div>
        <div className="col-md-1 d-none d-md-block">
          <a
            className={`btn btn-light slptls-next ${
              active === items.length - 1 ? 'disabled' : ''
            }`}
            onClick={() => {
              if (active < items.length - 1) {
                gotoNext()
              }
            }}
          >
            <span>Next</span>
          </a>
        </div>
      </div>
      <div className="row slptls-pd-md-bottom">
        <div className="col-md-8 col-sm-12 offset-md-2">
          <div className="row">
            {items.map((item, i) => Logo({ i, active, item, img, setActive }))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default makeCarousel(PartnerCarousel)
