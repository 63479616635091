export default {
  lang: `en`,
  head: {
    title: `SloopTools`,
    meta: {
      description: `SloopTools – the independent SCADA ecosystem`,
      keywords: `SloopTools,SCADA,HMI,WinCCOA`,
    },
  },
  navbar: {
    header: 'SloopTools',
    buttons: [
      {
        label: `Store`,
        href: `https://store.slooptools.com`,
      },
      {
        label: `Register`,
        href: `https://store.slooptools.com/user/register`,
      },
    ],
  },
  hero: {
    header: `SloopTools`,
    subheader: `The independent SCADA ecosystem`,
    videoSrc: '/SloopTools_EN.mp4',
  },
  intro: {
    menuLabel: 'Home',
    image: `logo_large`,
    header: `The Single Point of Information in the SCADA/HMI world.`,
    text: `SloopTools connect SCADA / HMI OEMs, integrators and end customers
    on a vendor-independent platform.
    SloopTools has developed multiple services for this purpose.`,
  },
  platform: {
    hero: {
      header: `SloopTools platform`,
      subheader: `The independent SCADA ecosystem`,
      videoSrc: '/SloopTools_PLatform_EN.mp4',
    },
    accordion: {
      text: `Do you want to create new added value of your product through open innovation? SloopTools platform was created to create your digital ecosystem. Grow your product through open innovation and your community.`,
    },
    menuLabel: 'Platform',
    header: `SloopTools platform`,
    subheader: `Do you want to create new added value of your product through open innovation? SloopTools platform was created to create your digital ecosystem. Grow your product through open innovation and your community.`,
    buttons: [
      {
        label: `Get in touch`,
        href: `mailto:info@slooptools.com?subject=Let's%20get%20in%20touch%20about%20SloopTools%20platform&body=I%20kindly%20ask%20you%20to%20contact%20me%20about%20SloopTools%20platform.`,
      },
    ],
    features: {
      header: `SloopTools platform Features`,
      items: [
        {
          icon: 'marketplace',
          header: `Digital Marketplace`,
          text: `A full-fledged store focused on use as a B2B marketplace and available 24/7.`,
        },
        {
          icon: 'business_system',
          header: `Business System`,
          text: `The transaction between customers and providers of digital services with agency fee needs a new business system.`,
        },
        {
          icon: 'community',
          header: `Community Builder`,
          text: `The fuel of your ecosystem is the community and to push it SloopTools has designed several community builder modules`,
        },
        {
          icon: 'paas_service',
          header: `Platform As A Service`,
          text: `The SloopTools platform is designed as a modular system which can be extended in all direction with open APIs.`,
        },
        {
          icon: 'provider_admin',
          header: `Provider Administration`,
          text: `The extensive provider backend allows the providers to make all the necessary settings themselves and supports placing the product in the best possible way`,
        },
        {
          icon: 'software',
          header: `Software / Service`,
          text: `Extensive functionality to successfully place and distribute products and services designed for industry via in the marketplace`,
        },
      ],
    },
    products: {
      header: `References`,
      // linkLabel: `Go to store`,
    },
  },
  store: {
    hero: {
      header: `SloopTools store`,
      subheader: `The independent SCADA ecosystem`,
      videoSrc: '/SloopTools_DE.mp4',
    },
    accordion: {
      text: `The SloopTools store is transforming your SCADA/HMI functionality into a high-quality sellable Add-on which is easy to find. In the SloopTools store you can buy and sell SCADA / HMI Add-ons.`,
    },
    menuLabel: 'Store',
    header: `SloopTools store`,
    subheader: `The SloopTools store is transforming your SCADA/HMI functionality into a high-quality sellable Add-on which is easy to find. In the SloopTools store you can buy and sell SCADA / HMI Add-ons.`,
    buttons: [
      {
        label: `Go to Store`,
        href: `https://store.slooptools.com`,
      },
    ],
    features: {
      header: `SloopTools store Features`,
      items: [
        {
          icon: 'presentation',
          header: `Add-on Presentation`,
          text: `Companies can present their add-ons and provide them with additional information.`,
        },
        {
          icon: 'easy_search',
          header: `Easy Search`,
          text: `Companies can search for missing functionalities or add-ons.`,
        },
        {
          icon: 'quality_gate',
          header: `Quality Gate`,
          text: `Add-ons are subject to in-depth quality control by SloopTools.`,
        },
        {
          icon: 'centralized_billing',
          header: `Central Billing`,
          text: `SloopTools manages the financial transactions for the companies.`,
        },
        {
          icon: 'contract_management',
          header: `Contract Management`,
          text: `SloopTools takes over the contract handling for the companies.`,
        },
        {
          icon: 'different_license_types',
          header: `Various License Types`,
          text: `Add-ons can be sold by a one-time, subscription or pay-per-use license.`,
        },
        {
          icon: 'ticket_system',
          header: `Ticketing System`,
          text: `The central ticket system handles the communication between the companies.`,
        },
        {
          icon: 'demo_licenses',
          header: `Demo Licences`,
          text: `A demo licence for an Add-on can easily be downloaded for testing purpose.`,
        },
        {
          icon: 'additional_services',
          header: `Additions`,
          text: `In addition to the add-ons services (maintenance, consulting, etc.) and features can be offered.`,
        },
      ],
    },
    products: {
      header: `Featured Add-Ons`,
      linkLabel: `Go to store`,
    },
  },
  connect: {
    hero: {
      header: `SloopTools connect`,
      subheader: `The independent SCADA ecosystem`,
      videoSrc: '/SloopTools_Connect_EN.mp4',
    },
    accordion: {
      text: `SloopTools connect creates an online platform within the industrial world. Using this platform, participants can work together and support each other to optimize their businesses.`,
    },
    menuLabel: 'Connect',
    header: 'SloopTools connect',
    subheader: `SloopTools connect creates an online platform within the industrial world. Using this platform, participants can work together and support each other to optimize their businesses.`,
    picturelable: 'Play Movie',
    features: {
      header: `SloopTools connect Features`,
      items: [
        {
          icon: 'presentation_company',
          header: `Company Presentation`,
        },
        {
          icon: 'easy_search_c',
          header: `Easy Search`,
        },
        {
          icon: 'find_services',
          header: `Find Services`,
        },
        {
          icon: 'central_communication',
          header: `Central Communication`,
        },
        {
          icon: 'network',
          header: `Networking`,
        },
      ],
    },
    buttons: [
      {
        label: `More information ...`,
        href: `http://connect.slooptools.com`,
      },
    ],
  },
  news: {
    menuLabel: `News`,
    header: `News`,
    linkLabel: `Go to article`,
  },
  partners: {
    menuLabel: `Partner`,
    header: `Partners about SloopTools`,
  },
  team: {
    menuLabel: `Team`,
    header: `We are SloopTools`,
    subheader: `Two professionals out of the industry who are complemented by partners and freelancers.`,
    picture: {
      left: {
        header: `Alexander Taker`,
        subheader: `Software Development`,
      },
      right: {
        header: `Lukas Schopp`,
        subheader: `CEO & Founder`,
      },
    },
    search: {
      header: `We are hiring:`,
    },
    support: {
      header: `Supported by:`,
    },
    team: {
      header: `Team`,
    },
    board: {
      header: `Business Advisory Board`,
    },
  },
  footer: {
    company: {
      name: `SloopTools GmbH`,
      address: {
        street: `Winzerweg 15a`,
        city: `Eisenstadt`,
        zip: `7000`,
        country: `Österreich`,
      },
      email: `info@slooptools.com`,
      additional: [`VAT-Number: ATU73030846`, `Register Nr.: FN487006k`],
    },
    newsletter: {
      header: `Newsletter`,
      popupHeader: 'Sign up to our Newsletter',
      popupSubheader: 'Keep in touch with us',
      placeholder: `Write your e-mail address`,
      notice: [
        `By signing up I accept the `,
        {
          label: `privacy policy`,
          href: `/dataprivacystatement`,
        },
        `.`,
      ],
      buttonLabel: `Send`,
      success: `The registration was successful. Thanks!`,
      invalidAddress: 'The e-mail address is invalid.',
    },
    navigation: {
      header: `Navigation`,
      additional: [
        {
          label: `About`,
          href: `/imprint`,
        },
        {
          label: `Privacy Policy`,
          href: `/dataprivacystatement`,
        },
        {
          label: `Go to Store`,
          href: `https://store.slooptools.com`,
        },
        {
          label: `Register`,
          href: `https://store.slooptools.com/user/register`,
        },
      ],
    },
    copyright: `© SloopTools GmbH. All rights reserved.`,
  },
}
