import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

export default ({
  translations: t,
  img,
  language,
  data,
  withLanguagePrefix,
}) => (
  <div className="slptls-pd-sm">
    <div className="container py-5">
      <div className="row align-items-center ">
        <div className="col-md-6 col-sm-12">
          <h2>{t.header}</h2>
          <p>{t.text}</p>
        </div>
        <div className="col-md-6 col-sm-12">
          <img src={img(t.image)} className="img-fluid" />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-4 offset-md-2 col-sm-12">
          {language === 'de' ? (
            <Img sizes={data.supportImageDE.childImageSharp.sizes} />
          ) : (
            <Img sizes={data.supportImageEN.childImageSharp.sizes} />
          )}
        </div>
        <div className="col-md-3 offset-md-1 col-sm-12">
          <Link to={withLanguagePrefix('/efre')}>
            {language === 'de' ? (
              <Img sizes={data.efreImageDE.childImageSharp.sizes} />
            ) : (
              <Img sizes={data.efreImageEN.childImageSharp.sizes} />
            )}
          </Link>
        </div>
      </div>
    </div>
  </div>
)
