import React from 'react'

import productImage from '../img/store.png'
import ArticleCarousel from './ArticleCarousel'

import shuffle from 'lodash/shuffle'
import ProductBlock from './ProductBlock'
import ProductHero from './ProductHero'

export default ({ translations: t, img, data }) => {
  const footer = (
    <div className="container">
      <div className="row slptls-pd-md-top">
        <div className="col">
          <h2>{t.products.header}</h2>
        </div>
      </div>

      <ArticleCarousel
        items={shuffle(data.products.edges.map(({ node }) => node))}
        img={img}
        translations={t.products}
        className="left"
      />
    </div>
  )

  return (
    <ProductBlock
      translations={t}
      img={img}
      className="slptls-store bg-light bg-blue-right"
      hero={
        <ProductHero
          translations={t.hero}
          image={data.storeHeroImage.childImageSharp}
        />
      }
      productImage={productImage}
      footer={footer}
    />
  )
}
