import React from 'react'

export default ({ translations: t, img }) => (
  <div>
    <div className="row slptls-pd-md-top">
      <div className="col">
        <h2>{t.header}</h2>
      </div>
    </div>
    <div className="row">
      {t.items.map(({ icon, header, text }, i) => (
        <div key={i} className="col-md-4">
          <img
            src={img(icon)}
            className="d-inline-block align-middle d-md-block slptls-mg-xs-right slptls-mg-xs-top"
          />
          <h4 className="d-inline-block align-middle slptls-pd-xs-top">{header}</h4>
          {text ? <p>{text}</p> : null}
        </div>
      ))}
    </div>
  </div>
)
