import React from 'react'

export default WrappedComponent => {
  return class Carousel extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        active: props.startCarouselWith || 0
      }
    }

    gotoNext() {
      this.setState({active: this.state.active + 1})
    }

    gotoPrev() {
      this.setState({active: this.state.active - 1})
    }

    setActive(active) {
      this.setState({active})
    }

    render() {
      const newProps = {
        active: this.state.active,
        gotoNext: this.gotoNext.bind(this),
        gotoPrev: this.gotoPrev.bind(this),
        setActive: this.setActive.bind(this)
      }
      return <WrappedComponent {...this.props} {...newProps} />
    }
  }
}
