import React from 'react'
import Helmet from 'react-helmet'

export default ({ translations: t }) => (
  <Helmet
    title={t.title}
    meta={Object.entries(t.meta).map(([name, content]) => ({ name, content }))}
/*    script={[
      {
        type: 'text/javascript',
        src:
          '//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js',
        'data-dojo-config': 'usePlainJson: true, isDebug: false',
      },
      {
        type: 'text/javascript',
        innerHTML: 'function waitForDojo() { if (window.dojoRequire) window.dojoRequire(["mojo/signup-forms/Loader"], function(L) { L.start({"baseUrl":"mc.us17.list-manage.com","uuid":"4962a24178af2ee37ed4e2cf5","lid":"2cfeeefcff","uniqueMethods":true})}); else window.setTimeout(waitForDojo, 1000); } waitForDojo();'
      }      
    ]}
*/
  />
)