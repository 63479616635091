import Intro from '../components/intro'
import Store from '../components/store'
import Connect from '../components/connect'
import News from '../components/news'
import Partners from '../components/partners'
import Team from '../components/team'
import Platform from './platform'

export default [
  { id: 'intro', component: Intro },
  { id: 'platform', component: Platform },
  { id: 'store', component: Store },
  { id: 'connect', component: Connect },
  { id: 'news', component: News },
  { id: 'partners', component: Partners },
  { id: 'team', component: Team },
]
